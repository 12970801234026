<template>
  <div class="w-full">
    <transition name="fade">
      <choose-login-modal v-show="modal" @closeModal="modal = false"/>
    </transition>
    <div class="w-full h-full flex flex-col items-center" :class="modal ? 'blur' : ''">
      <div class="wrapper">
        <div class="content container">
          <nav-bar white="true" @login="modal = true"/>
          <img alt="Pessoa" src="../../../assets/images/landing/people-black.png" class="wrapper_people"/>
          <div class="article">
            <div class="article_main">
               <div class="article_cards">
                <div class="article_card">
              <p class="article_title">BLACK FRIDAY 2021</p>
                </div>
                </div>
              <div class="article_cards">
               <div class="article_card">
                  <p class="article_card_title">R$ 50,00 de cashback a novos investidores!</p>
                  <p class="article_card_text">Valor será pago na carteira digital do investidor no mês de dezembro após primeiro investimento realizado até 30/11/2021, podendo ser utilizado somente para investimentos até 28/02/2022.
</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">R$ 50,00 na indicação de novos investidores!</p>
                  <p class="article_card_text">Receba R$ 50,00 de cashback ao indicar um novo investidor na plataforma.
Valor será pago na carteira digital do investidor no mês de dezembro após primeiro investimento realizado até 30/11/2021, podendo ser utilizado somente para investimentos até 28/02/2022.
</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">2% de cashback a investidores já cadastrados, sem investimento formalizado</p>
                  <p class="article_card_text">Cashback será pago a investidores já cadastrados que ainda não realizaram investimentos na plataforma.*
Valor será pago na carteira digital do investidor no mês de dezembro após primeiro investimento realizado até 30/11/2021, podendo ser utilizado somente para investimentos até 28/02/2022.</p>
                </div>

                 <div class="article_card">
                  <p class="article_card_text"> <button class="btn" @click="$router.push('/investimento/solicitar?i=BLACKFRIDAY2021')">Quero me cadastrar</button></p>
                  <p class="article_card_text"> <button class="btn btn2" @click="$router.push('/investidor/login?i=BLACKFRIDAY2021')">Ativar promoção</button></p>

                </div>

              </div>
            </div>
            <img alt="Pessoa" class="w-full h-88 mt-10 md:hidden" src="../../../assets/images/landing/people-black.png"/>
            <p class="article_title_mobile">BLACK FRIDAY <br/> 2021 <br /></p>
             <p class="article_title_mobile"><button class="btn_mobile" style="font-size: 0.6em" @click="$router.push('/investimento/solicitar?i=BLACKFRIDAY2021')">Quero me cadastrar</button></p>
              <p class="article_title_mobile" style="top: 250px"><button class="btn_mobile btn2" style="font-size: 0.6em" @click="$router.push('/investidor/login?i=BLACKFRIDAY2021')">Já sou cadastrado</button></p>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col items-center">
        <div class="article_cards_mobile container">
          <div class="article_card">
                  <p class="article_card_title">R$ 50,00 de cashback a novos investidores!</p>
                  <p class="article_card_text">Valor será pago na carteira digital do investidor no mês de dezembro após primeiro investimento realizado até 30/11/2021, podendo ser utilizado somente para investimentos até 28/02/2022.
</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">R$ 50,00 na indicação de novos investidores!</p>
                  <p class="article_card_text">Receba R$ 50,00 de cashback ao indicar um novo investidor na plataforma.
Valor será pago na carteira digital do investidor no mês de dezembro após primeiro investimento realizado até 30/11/2021, podendo ser utilizado somente para investimentos até 28/02/2022.
</p>
                </div>
                <div class="article_card">
                  <p class="article_card_title">2% de cashback a investidores já cadastrados, sem investimento formalizado</p>
                  <p class="article_card_text">Cashback será pago a investidores já cadastrados que ainda não realizaram investimentos na plataforma.*
Valor será pago na carteira digital do investidor no mês de dezembro após primeiro investimento realizado até 30/11/2021, podendo ser utilizado somente para investimentos até 28/02/2022.</p>
                </div>

                 <div class="article_card">
                  <p class="article_card_text" > <button class="btn_mobile" style="margin-bottom: .7rem" @click="$router.push('/investimento/solicitar?i=BLACKFRIDAY2021')">Quero me cadastrar</button> <br />
                 <button class="btn_mobile btn2 mt-0" style="margin-top: 0" @click="$router.push('/investidor/login?i=BLACKFRIDAY2021')">Ativar promoção</button></p>

                </div>
        </div>
      </div>


    </div>
    <Footer partial="true"/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import Footer from '@/components/Footer/Footer.vue'
import CarouselItems from '@/components/Carousel/Carousel.vue'

export default {
  name: 'investor',
  components: { NavBar, ChooseLoginModal, Footer, CarouselItems },
  data () {
    return {
      modal: false
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>

.wrapper
  @apply relative flex flex-col items-end w-full h-full bg-cover bg-center bg-no-repeat
  background-image: url('../../../assets/images/landing/bg-black-1.png')
  @screen lg
    height: auto

.content
  @apply relative h-full flex flex-col items-center

.wrapper_people
  @apply hidden
  @screen md
    transform: scaleX(-1)
    @apply block absolute bottom-0 right-0 ml-0
    width: 42em
    height: 42em
  @screen lg
    transform: scaleX(1)
    @apply left-0
    width: 43em
    height: 43em
  @screen xl
    transform: scaleX(1)
    @apply left-0 w-144 h-144

.blur
  transition-duration: .25s
  filter: blur(8px)
  -webkit-filter: blur(8px)

.article
  @apply flex flex-col items-end my-auto

.article_main
  margin-top: 2em
  @apply w-full flex flex-col items-center
  @screen md
    @apply w-full mb-4
  @screen lg
    @apply w-3/6 mb-10
  @screen xl
    @apply w-4/6 mr-20

.article_title
  color: #e1f5ed
  @apply text-2xl font-bold leading-normal text-center
  @screen md
    @apply w-10/12 text-2xl  font-bold leading-normal
  @screen lg
    @apply w-full text-4xl  font-bold leading-normal

  &_mobile
    @screen md
      @apply hidden
    @extend .article_title
    position: absolute
    top: 200px

    .btn_mobile
      margin-top: 150px


.article_cards
  @apply hidden
  margin-left: 0
  @screen md
    margin-left: -250px
    @apply block w-8/12 flex flex-wrap
  @screen lg
    margin-left: 0
    @apply block w-full flex flex-wrap

.article_cards_mobile
  background: url('../../../assets/images/landing/bg-black-2.jpg')
  @apply block flex flex-wrap mb-0
  @screen md
    @apply hidden
  @screen lg
    @apply hidden
  .article_card_text
    color: #e0fff3
    text-shadow: none
    @apply text-center
  .article_card_title
    @apply text-center

.article_card
  @apply relative w-full mt-6

.article_card_title
  color: #94f2cc
  @apply text-lg font-bold

.article_card_text
  color: #e0fff3
  text-shadow: #333 1px 1px 0
  @apply w-full text-sm mt-2 text-justify
  @screen lg
    @apply w-full text-sm mt-2

.btn
  @apply hidden text-sm
  @screen md
    @apply block w-40 h-10 bg-primary text-white rounded-full m-auto
    &:hover, &:focus
      @apply outline-none
  @screen lg
    @apply block w-40 h-10 bg-primary text-white rounded-full m-auto
    &:hover, &:focus
      @apply outline-none



.btn_mobile
  @apply w-40 h-10 bg-primary text-white rounded-full mt-3 mb-8
  &:hover, &:focus
    @apply outline-none
  @screen md
    @apply hidden
  @screen lg
    @apply hidden

.btn2
  @apply bg-secondary
  @screen md
    @apply bg-secondary
  @screen lg
    @apply bg-secondary

.compare_main
  @apply flex flex-col items-center w-full bg-secondary
  background: linear-gradient(to right, #5316CD, #625FB0)

.compare
  @apply flex flex-col items-center py-12
  @screen md
    @apply flex flex-row items-center py-12
  @screen lg
    @apply flex flex-row items-center py-12

.compare_left
  @apply w-full
  @screen lg
    @apply w-1/2

.compare_left_title
  @apply text-white text-2xl mb-6
  @screen lg
    @apply text-white text-4xl mb-8

.compare_left_text
  @apply text-white text-xss text-justify mt-2
  @screen lg
    @apply text-white text-sm text-justify mt-2

.compare_right
  @apply w-full flex justify-center items-center mt-6
  @screen lg
    @apply w-1/2 flex justify-center items-center



</style>
